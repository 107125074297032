import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av kontovelger`}</h2>
    <p>{`Kontovelger brukes som navnet tilsier til å velge konto på de ulike platformene våre. Forskjellen på denne og en vanlig dropdown, er at den er tilpasset situasjoner der man ønsker å velge en konto, siden den vil vise valgt kontonummer og saldo, etter du har valgt et alternativ.`}</p>
    <p>{`I mobilbanken så er foretrukken løsning `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openAccountPicker`}</code>{` i stedet for denne.`}</p>
    <h2>{`AccountSelector`}</h2>
    <p>{`Kontovelger for når du bare trenger å velge en konto`}</p>
    <DSExample name="account_selector_AccountSelector" mdxType="DSExample" />
    <p>{`Kontovelger som også viser saldo/beløp`}</p>
    <DSExample name="account_selector_AccountSelector_showBalance" mdxType="DSExample" />
    <h2>{`AccountSelectorMulti`}</h2>
    <p>{`Kontovelger med støtte for å velge flere kontoer på én gang.`}</p>
    <DSExample name="account_selector_AccountSelectorMulti" mdxType="DSExample" />
    <h2>{`Varianter`}</h2>
    <p>{`Trenger du å tillate at brukere kan skrive inn sitt eget kontonummer kan du aktivere dette ved å sende med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`allowCustomAccount={true}`}</code></p>
    <DSExample name="account_selector_AccountSelector_allowCustomAccount" mdxType="DSExample" />
    <p>{`Hvis du ikke ønsker at kontonummeret en bruker skriver inn skal formatteres, kan du skru det av med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`formatAccountNumber={false}`}</code></p>
    <DSExample name="account_selector_AccountSelector_accountNumberFormatting_off" mdxType="DSExample" />
    <p>{`Dersom du ønsker å gjøre endringer på selve listevisningen, kan du gjøre det med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`listElementBody`}</code></p>
    <DSExample name="account_selector_AccountSelector_customListDesign" mdxType="DSExample" />
    <p>{`Dersom du ønsker å skjule kontodetaljer, kan du bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hideAccountDetails`}</code></p>
    <DSExample name="account_selector_AccountSelector_doNotshowAccountDetails" mdxType="DSExample" />
    <p>{`Dersom du ønsker å ha ekstra tekst på bunnen av lista, kan du bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`postListElement`}</code></p>
    <DSExample name="account_selector_AccountSelector_postListElement" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      